import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import bg from '../assets/img/bg-gallery.svg';
import avatar1 from '../assets/img/avatar-1.png';
import charMain1 from '../assets/img/character-main-1.jpeg';
import charMain2 from '../assets/img/character-main-2.jpeg';
import charMain3 from '../assets/img/character-main-3.jpeg';
import charMain4 from '../assets/img/character-main-4.jpeg';
import charMain5 from '../assets/img/character-main-5.jpeg';
import charNFT1 from '../assets/img/character-nft-1.jpeg';
import charNFT2 from '../assets/img/character-nft-2.jpeg';
import charNFT3 from '../assets/img/character-nft-3.jpeg';
import charNFT4 from '../assets/img/character-nft-4.jpeg';
import charNFT5 from '../assets/img/character-nft-5.jpeg';
import charNFT6 from '../assets/img/character-nft-6.jpeg';
import charNFT7 from '../assets/img/character-nft-7.jpeg';


function CardMain(props) {
  return (
    <>
      <div className="card-character focus max-lg:mx-6">
        <div className="relative z-[1] bg-primary-2/60 text-white p-2">
          <img src={props.char} alt="character" className="w-full" />
          <div className="leading-tight px-2">
            <div className="flex justify-between gap-4 mt-3">
              <h3 className="font-theme-1 leading-tight text-lg mt-1.5 mb-3">
                {props.title}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="card-character absolute -top-6 bottom-0 -left-11 -z-[1] scale-[.6] sm:hidden">
        <div className="relative z-[1] bg-primary-2/60 text-white p-4 h-full">
          <img src={props.char} alt="character" className="w-full h-4/5" />
        </div>
      </div>
      <div className="card-character absolute -top-6 bottom-0 -right-11 -z-[1] scale-[.6] sm:hidden">
        <div className="relative z-[1] bg-primary-2/60 text-white p-4 h-full">
          <img src={props.char} alt="character" className="w-full h-4/5" />
        </div>
      </div>
    </>
  );
}

function CardNFT(props) {
  return (
    <>
      <div className="card-character focus max-lg:mx-6">
        <div className="relative z-[1] bg-primary-2/60 text-white p-2">
          <img src={props.char} alt="character" className="w-full" />
          <div className="leading-tight px-2">
            <div className="flex justify-between gap-4 mt-3 mb-1">
              <h3 className="font-theme-1 leading-tight text-lg mt-1.5">
                {props.title}
              </h3>
              <img src={props.avatar} alt="avatar" className="w-8 h-8 object-cover rounded-full -mb-8" />
            </div>
            <small className="block">created by {props.author}</small>
            <p className="font-theme-1 mt-3 mb-2 before:content-[''] before:w-2.5 before:h-2.5 before:mr-1.5 before:rounded-full before:inline-block before:bg-white/50">{props.price}</p>
          </div>
        </div>
      </div>
      <div className="card-character absolute -top-8 bottom-0 -left-14 -z-[1] scale-[.6] sm:hidden">
        <div className="relative z-[1] bg-primary-2/60 text-white p-4 h-full">
          <img src={props.char} alt="character" className="w-full h-4/5" />
        </div>
      </div>
      <div className="card-character absolute -top-8 bottom-0 -right-14 -z-[1] scale-[.6] sm:hidden">
        <div className="relative z-[1] bg-primary-2/60 text-white p-4 h-full">
          <img src={props.char} alt="character" className="w-full h-4/5" />
        </div>
      </div>
    </>
  );
}

function Gallery() {
  const data = {
    main: [
      {
        title: 'Jack',
        char: `${charMain1}`
      },
      {
        title: 'Liona',
        char: `${charMain2}`
      },
      {
        title: 'Gareth',
        char: `${charMain3}`
      },
      {
        title: 'Seraphina',
        char: `${charMain4}`
      },
      {
        title: 'Sylvan',
        char: `${charMain5}`
      }
    ],
    nft: [
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT1}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT2}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT3}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT4}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT5}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT6}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      },
      {
        title: 'Arcane Labyrinth NFT',
        char: `${charNFT7}`,
        author: 'Arcane Labyrinth',
        price: '200 XRD',
        avatar: `${avatar1}`
      }
    ]
  }

  return (
    <section id="gallery" className="relative">
      <div className="section relative z-[1] pb-14 lg:pb-20 overflow-x-hidden">
        <div className="px-4">

          <div className="relative md:w-fit my-7 lg:my-9 mx-auto text-center">
            <div className="font-theme-1 text-gray-800 text-lg lg:text-3xl md:float-left mt-1 md:mr-4 max-md:mb-1">
              There’s a lot of
            </div>
            <div className="title gradient max-md:text-3xl max-md:after:mt-12 max-md:after:-mb-12">
              Character
            </div>
            <div className="font-theme-1 text-gray-800 text-lg lg:text-3xl md:float-right mt-1 md:ml-4">
              you can choose
            </div>
          </div>

          <h2 className="sr-only">There's a lot of Characters you can choose</h2>

          <Tabs className="max-md:pt-5 mb-10">
            <TabList className="max-sm:flex text-center pt-4 pb-4 sm:pb-8 md:pb-12 max-md:-mx-4">
              <Tab className="pill max-sm:w-full">
                Main Character
              </Tab>
              <Tab className="pill max-sm:w-full">
                NFT Character
              </Tab>
            </TabList>
            <TabPanel>
              <Splide hasTrack={ false } aria-label="Main Character" id="char-main" className="max-lg:-mx-8 lg:px-20 xl:px-28" options={ {
                perPage: 4,
                perMove: 1,
                gap: '5rem',
                // focus: 'center',
                breakpoints: {
                  1536: {
                    perPage: 3,
                  },
                  1280: {
                    gap: '2rem',
                  },
                  1024: {
                    perPage: 3,
                  },
                  768: {
                    perPage: 2,
                  },
                  640: {
                    perPage: 1,
                  },
                },
              } }>
                <SplideTrack>
                  {data.main.map((item, index) => (
                    <SplideSlide key={index}>
                      <CardMain 
                        title={item.title}
                        char={item.char}
                      />
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <ArrowLongRightIcon className="w-4 h-4" />
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <ArrowLongRightIcon className="w-4 h-4" />
                  </button>
                </div>
              </Splide>
            </TabPanel>
            <TabPanel>
              <Splide hasTrack={ false } aria-label="NFT Character" id="char-nft" className="max-lg:-mx-8 lg:px-20 xl:px-28" options={ {
                perMove: 1,
                perPage: 4,
                gap: '5rem',
                // focus: 'center',
                breakpoints: {
                  1536: {
                    perPage: 3,
                  },
                  1280: {
                    gap: '2rem',
                  },
                  1024: {
                    perPage: 3,
                  },
                  768: {
                    perPage: 2,
                  },
                  640: {
                    perPage: 1,
                  },
                },
              } }>
                <SplideTrack>
                  {data.nft.map((item, index) => (
                    <SplideSlide key={index}>
                      <CardNFT 
                        title={item.title}
                        char={item.char}
                        author={item.author}
                        price={item.price}
                        avatar={item.avatar}
                      />
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <ArrowLongRightIcon className="w-4 h-4" />
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <ArrowLongRightIcon className="w-4 h-4" />
                  </button>
                </div>
              </Splide>
            </TabPanel>
          </Tabs>

        </div>
      </div>
      <img src={bg} alt="bg" className="w-full absolute bottom-0 -z-[1]" />
    </section>
  );
}

export default Gallery;
