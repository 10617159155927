function Vision() {
  return (
    <section id="vision">
      <div className="relative text-gray-700">
        <div className="section flex items-center justify-center lg:justify-between gap-4 max-lg:flex-wrap my-14 lg:my-20 text-center">

          <div className="max-w-[115ch] mx-auto px-4">
            <div data-title="Our" className="title gradient my-7 lg:my-9 before:content-[attr(data-title)] before:font-normal before:text-xl before:absolute before:-top-7 before:left-0 before:right-0 before:m-auto before:text-gray-800 lg:before:text-3xl lg:before:-top-10">
              Vision
            </div>
            <h2 className="sr-only">Our Vision</h2>
            <p className="text-lg lg:text-2xl">Creating a game that provides a fun and challenging playing experience, with innovative features and a unique level design with a classic concept, so that it can give a nostalgic feeling and become a game that is loved by gamers all over the world.</p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Vision;
