import React, { useState } from 'react';

import logo from '../assets/img/logo-arcane.png';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

function Navbar() {
  const [menu, setMenu] = useState(false);

  const handleClickMenu = () => {
    if ( menu !== true ) {
      setMenu(true);
    } else {
      setMenu(false);
    }
    document.documentElement.classList.toggle('!overflow-hidden');
    document.querySelector('.navbar').classList.toggle('hidden');
    document.querySelectorAll('.menu').forEach(item => {
      item.classList.toggle('!bg-white');
      item.classList.toggle('!shadow-none');
      item.classList.toggle('!text-primary-2');
      item.querySelectorAll('svg').forEach(icon => {
        icon.classList.toggle('hidden');
      });
    });
  };

  const handleClickBackTop = () => {
    window.scroll(0,0);
  };

  const handleClickScroll = (e) => {
    e.preventDefault();
    if ( document.documentElement.clientWidth < 1024 ) {
      document.querySelector('.menu').click();
    }
    let target = document.querySelector(e.target.getAttribute("href"));
    let headerOffset = document.querySelector("header").clientHeight;
    let elementPosition = target.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scroll(0, offsetPosition);
  };

  return (
    <section className="lg:bg-white">
      <div className="section">
        <div className="flex items-center justify-between max-lg:flex-wrap lg:py-6">

          <div className="flex items-center justify-between max-lg:w-screen bg-white max-lg:-mx-4 max-lg:p-4">
            <button type="button" onClick={handleClickBackTop}>
              <img src={logo} className="brand" alt="logo" />
            </button>
            <button type="button" className="button primary menu py-1 px-2 lg:hidden" onClick={handleClickMenu}>
              <Bars3Icon className="h-6 w-6" />
              <XMarkIcon className="h-6 w-6 hidden" />
            </button>
          </div>

          <nav className="navbar hidden lg:block">
            <ul>
              <li>
                <a href="#story" onClick={handleClickScroll}>Story</a>
              </li>
              <li>
                <a href="#gallery" onClick={handleClickScroll}>Gallery</a>
              </li>
              <li>
                <a href="https://app.radland.io/collections/7" target="_blank" rel="noopener noreferrer">Buy NFT</a>
              </li>
              <li>
                <a href="#tokenomics" onClick={handleClickScroll}>Tokenomics</a>
              </li>
              <li>
                <a href="#benefit" onClick={handleClickScroll}>Benefit</a>
              </li>
              <li>
                <a href="#about" onClick={handleClickScroll}>About</a>
              </li>
            </ul>
            <a href="mailto:official@arcanelabyrinth.com" className="button primary lg:hidden mt-8 mb-2">
              Contact Us
            </a>
          </nav>

          <a href="mailto:official@arcanelabyrinth.com" className="button primary max-lg:hidden">
            Contact Us
          </a>

        </div>
      </div>
    </section>
  );
}

export default Navbar;
