import char1 from '../assets/img/character-1.png';
import char2 from '../assets/img/character-2.png';
import glow1 from '../assets/img/glow-1.png';

function About() {
  return (
    <section id="about">
      <div className="relative bg-background-1 text-white">
        <div className="section flex items-center justify-around lg:justify-between gap-4 max-lg:flex-wrap py-14 lg:py-20 md:text-center">

          <div className="lg:order-2 md:w-3/4 lg:w-1/2 mx-auto z-[1] px-4">
            <div data-title="All" className="title mt-6 mb-14 lg:mt-8 lg:mb-20 before:content-[attr(data-title)] before:font-normal before:text-lg before:absolute before:-left-8 before:bottom-3 lg:before:text-3xl lg:before:-left-12 max-lg:before:mb-px max-md:ml-9">
              About Us
            </div>
            <h2 className="sr-only">All About Us</h2>
            <p>Arcane Labyrinth: Uncovering the Mysteries of a Fantasy World is an adventure game that offers the experience of playing in a labyrinth that has 101 different floors. In this game, players will play characters who are in a magical fantasy world filled with various kinds of obstacles and challenges.</p>
            <p>The goal of this game is to complete each floor of the labyrinth by fighting monsters and collecting treasures scattered throughout the maze. Players can also find new weapons and magic that can help them in fighting enemies and solving more difficult maze floors.</p>
            <p>This game also offers a play-to-earn system that allows players to earn prizes in the form of $ARC tokens when they complete each floor of the maze. These $ARC tokens can be used to purchase in-game items, such as new, more powerful weapons, pets and magic, or even to be exchanged for real money outside of the game.</p>
            <p>The graphics of this game use a 2D pixel art style that combines fantasy and magic nuances, giving the players an interesting and fun nostalgic impression. The difficulty of the game is set in such a way that players will find the challenge quite exciting, but still fair and not too difficult to overcome.</p>
          </div>

          <img src={char2} alt="character" className="max-lg:h-[250px] lg:order-1 w-1/3 md:w-1/4 object-cover inline mt-10" />

          <img src={char1} alt="character" className="max-lg:h-[250px] lg:order-3 w-1/3 md:w-1/4 object-cover inline mt-10 transform -scale-x-100" />

          <img src={glow1} alt="glow" className="absolute top-0 left-0 right-0 m-auto object-cover max-lg:h-[400px]" />

        </div>
      </div>
    </section>
  );
}

export default About;
