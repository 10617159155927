import React from 'react';
import classnames from 'classnames';
import * as lodash from 'lodash';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { ArrowLongRightIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

import bg from '../assets/img/bg-benefit.svg';
import pattern from '../assets/img/pattern-benefit.svg';
import image1 from '../assets/img/benefit-1.png';
import image2 from '../assets/img/benefit-2.png';
import image3 from '../assets/img/benefit-3.png';
import image4 from '../assets/img/benefit-4.png';
import image5 from '../assets/img/benefit-5.png';
import image6 from '../assets/img/benefit-6.png';

const ReadMoreText = ({ text }) => {
  const [clamped, setClamped] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);
  const containerRef = React.useRef(null);
  const handleClick = () => setClamped(!clamped);

  React.useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains('line-clamp-2');
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add('line-clamp-2');
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove('line-clamp-2');
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef]);

  return (
    <>
      <div ref={containerRef} className={classnames('mb-2', clamped && 'line-clamp-2')}>
        {text}
      </div>
      {showButton && (
        <button onClick={handleClick} className='block w-full text-center pt-1 -mb-2'>
          {clamped ? <ChevronDownIcon className='w-5 h-5 mx-auto' /> : <ChevronUpIcon className="w-5 h-5 mx-auto" />}
        </button>
      )}
    </>
  );
};

function Card(props) {
  return (
    <>
      <div className="card-benefit focus p-6 sm:p-8 max-sm:h-full">
        <div className="relative overflow-hidden max-sm:h-full">
          <div className="relative z-[1] p-6 sm:p-8 font-theme-1 font-bold max-sm:h-full">
            <img src={props.thumbnail} alt="thumbnail" className="w-full p-4 my-3" />
            <span className="sr-only" title="nft icons">Nft icons created by Freepik - Flaticon</span>
            <ReadMoreText text={props.content} />
          </div>
          <img src={pattern} alt="pattern" className="absolute top-0 w-full" />
        </div>
      </div>
      <div className="card-benefit p-8 absolute -top-10 -bottom-10 -left-16 -z-[1] scale-[.6] sm:hidden">
        <div className="relative overflow-hidden h-full">
          <div className="relative z-[1] p-6 font-theme-1 font-bold invisible">
            <img src={props.thumbnail} alt="thumbnail" className="w-full p-4 my-3" />
          </div>
          <img src={pattern} alt="pattern" className="absolute top-0 w-full" />
        </div>
      </div>
      <div className="card-benefit p-8 absolute -top-10 -bottom-10 -right-16 -z-[1] scale-[.6] sm:hidden">
        <div className="relative overflow-hidden h-full">
          <div className="relative z-[1] p-6 font-theme-1 font-bold invisible">
            <img src={props.thumbnail} alt="thumbnail" className="w-full p-4 my-3" />
          </div>
          <img src={pattern} alt="pattern" className="absolute top-0 w-full" />
        </div>
      </div>
    </>
  );
}

function Benefit() {
  const data = [
    {
      thumbnail: `${image1}`,
      content: '8% total supply for NFT holder'
    },
    {
      thumbnail: `${image2}`,
      content: 'Get a Sword for free in the Game'
    },
    {
      thumbnail: `${image3}`,
      content: 'Get Magic for free in the Game'
    },
    {
      thumbnail: `${image4}`,
      content: 'Get Pets for free in the Game'
    },
    {
      thumbnail: `${image5}`,
      content: 'Will Unlock the Mining Feature in the Future'
    },
    {
      thumbnail: `${image6}`,
      content: 'NFT holders will get a VIP badge on their game profile'
    }
  ]

  return (
    <section id="benefit" className="relative bg-primary-3">
      <div className="section relative z-[1] pt-8 pb-14 lg:pb-20 overflow-x-hidden">
        <div className="px-4">

          <div className="relative md:w-fit my-7 lg:my-9 max-lg:mx-auto text-center">
            <div className="font-theme-1 text-gray-800 text-lg lg:text-3xl md:float-left mt-1 md:mr-4 max-md:mb-1">
              What’s
            </div>
            <div className="title gradient max-md:text-3xl max-md:after:mt-12 max-md:after:-mb-12">
              Benefits
            </div>
            <div className="font-theme-1 text-gray-800 text-lg lg:text-3xl md:float-right mt-1 md:ml-4">
              hodler NFTs?
            </div>
          </div>

          <h2 className="sr-only">What’s Benefits hodler NFTs?</h2>

          <Splide hasTrack={ false } aria-label="Benefits" className="-mx-8" options={ {
            perMove: 1,
            perPage: 5,
            pagination: false,
            breakpoints: {
              1280: {
                perPage: 4,
              },
              1024: {
                perPage: 3,
              },
              768: {
                perPage: 2,
              },
              640: {
                perPage: 1,
              },
            },
          } }>
            <SplideTrack>
              {data.map((item, index) => (
                <SplideSlide key={index}>
                  <Card 
                    thumbnail={item.thumbnail}
                    content={item.content}
                  />
                </SplideSlide>
              ))}
            </SplideTrack>
            <div className="splide__arrows">
              <button className="splide__arrow splide__arrow--prev">
                <ArrowLongRightIcon className="w-4 h-4" />
              </button>
              <button className="splide__arrow splide__arrow--next">
                <ArrowLongRightIcon className="w-4 h-4" />
              </button>
            </div>
          </Splide>

        </div>
      </div>
      <img src={bg} alt="bg" className="w-full absolute bottom-0" />
    </section>
  );
}

export default Benefit;
