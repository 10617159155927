import Navbar from './components/Navbar'
import Welcome from './components/Welcome'
import Story from './components/Story'
import Preview from './components/Preview'
import Gallery from './components/Gallery'
import Tokenomics from './components/Tokenomics'
import Benefit from './components/Benefit'
import Vision from './components/Vision'
import Mission from './components/Mission'
import About from './components/About'
import Copyright from './components/Copyright'

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <main>
        <Welcome />
        <Story />
        <Preview />
        <Gallery />
        <Tokenomics />
        <Benefit />
        <Vision />
        <Mission />
        <About />
      </main>
      <footer>
        <Copyright />
      </footer>
    </div>
  );
}

export default App;
