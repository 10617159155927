import { useRef } from 'react';
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";

import glow1 from '../assets/img/glow-1.png';
import chart from '../assets/img/chart.svg';
import logo from '../assets/img/logo-arcane-white.png';

ChartJS.register(ArcElement);

export const options = {
  events: ['click', 'touchstart'],
  cutout: '85%',
  responsive: true,
}

export const data = {
  labels: [
    'Token In-Game',
    'Airdrop & Liquidity Provider',
    'Mining In-Game',
    'Team Development',
    'NFT Holder',
    'Investor',
    'Public Sale',
    'Private Sale',
    'Marketing',
    'Advisor'
  ],
  datasets: [
    {
      label: 'Percentage',
      data: [40, 10, 10, 8, 8, 8, 5, 5, 4, 2],
      backgroundColor: [
        'rgba(255, 90, 0, 1)',
        'rgba(207, 74, 0, 1)',
        'rgba(165, 58, 0, 1)',
        'rgba(115, 40, 0, 1)',
        'rgba(115, 69, 0, 1)',
        'rgba(147, 88, 0, 1)',
        'rgba(185, 111, 0, 1)',
        'rgba(227, 136, 0, 1)',
        'rgba(247, 156, 23, 1)',
        'rgba(255, 176, 61, 1)',
      ],
      borderColor: [
        'rgba(255, 90, 0, 1)',
        'rgba(207, 74, 0, 1)',
        'rgba(165, 58, 0, 1)',
        'rgba(115, 40, 0, 1)',
        'rgba(115, 69, 0, 1)',
        'rgba(147, 88, 0, 1)',
        'rgba(185, 111, 0, 1)',
        'rgba(227, 136, 0, 1)',
        'rgba(247, 156, 23, 1)',
        'rgba(255, 176, 61, 1)',
      ],
      borderWidth: 1.5,
      hoverBorderColor: 'rgba(227, 193, 119, 1)',
    },
  ],
}

function Box1(props) {
  return (
    <article className="[&.active_.glow]:py-3.5 relative flex flex-col text-left bg-primary-2/5 w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.3333%-1rem)] xl:w-[calc(25%-1.25rem)]">
      <div className="flex items-center gap-4 py-4 px-6">
        <div className="text-3xl">{props.percentage}</div>
        <div className="text-sm">{props.title}</div>
      </div>
      <div className="bg-gradient-to-b from-[#FFA800]/0 via-[#FFA800]/30 to-[#FFA800] w-full absolute bottom-0 -z-[1] transition-all glow"></div>
      <div className="bg-[#E3C177] h-[2px] mt-auto"></div>
    </article>
  );
}

function Box2(props) {
  return (
    <article className={"bg-primary-2/5 text-left text-xs whitespace-nowrap my-2 lg:border-primary-1 " + (props.align === 'left' ? 'lg:border-l' : 'lg:border-r')}>
      <div className="flex items-center justify-between gap-4 py-3 px-4">
        <div>{props.title} :</div>
        <div>{props.content}</div>
      </div>
    </article>
  );
}

function Tokenomics() {
  const chartRef = useRef();
  const handleClickChart = (event) => {
    let activeChart = getElementAtEvent(chartRef.current, event);
    let label = document.querySelectorAll('#chartLabel article');
    if ( activeChart.length > 0 ) {
      let order = activeChart[0].index;
      label.forEach(item => {
        item.classList.remove('active');
      });
      label[order].classList.add('active');
    } else {
      label.forEach(item => {
        item.classList.remove('active');
      });
    }
  }

  return (
    <section id="tokenomics">
      <div className="relative bg-background-1 text-white">
        <div className="section py-14 lg:py-20 flex justify-center text-center">

          <div className="w-full z-[1] px-4">
            <h2 className="title mt-6 mb-14 lg:mt-8 lg:mb-20">
              Tokenomics
            </h2>

            <div className="lg:flex items-center gap-8 mb-10">
              <div className="lg:order-2 mx-auto md:w-3/4 lg:w-1/2 xl:w-3/5 relative flex justify-center [&_canvas]:cursor-pointer [&_canvas]:rounded-full">
                <img src={chart} alt="chart" className="absolute top-0 bottom-0 left-0 right-0 w-4/5 h-4/5 rounded-full m-auto" />
                <img src={logo} alt="logo" className="absolute top-0 bottom-0 left-0 right-0 rounded-full m-auto img-crisp" />
                <Doughnut
                  data={data}
                  options={options}
                  ref={chartRef}
                  onClick={handleClickChart}
                />
              </div>
              <div className="lg:order-1 lg:w-1/4 xl:w-1/5 max-lg:mt-10">
                <Box2 
                  title="Name"
                  content="Arcane Labyrinth"
                  align="right"
                />
                <Box2 
                  title="Symbol"
                  content="ARC"
                  align="right"
                />
                <Box2 
                  title="Chain"
                  content="RadixDLT"
                  align="right"
                />
              </div>
              <div className="lg:order-3 lg:w-1/4 xl:w-1/5">
                <Box2 
                  title="Supply"
                  content="100,000,000 Tokens"
                  align="left"
                />
                <Box2 
                  title="Token Type"
                  content="Utility"
                  align="left"
                />
                <Box2 
                  title="Use"
                  content="Governance - Play to Earn"
                  align="left"
                />
              </div>
            </div>

            <div className="font-theme-1 flex flex-wrap gap-6 lg:-ml-1.5 md:-mr-1.5" id="chartLabel">
              {data.labels.map((item, index) => (
                <Box1 
                  key={index}
                  title={item}
                  percentage={`${data.datasets[0].data[index]}%`}
                />
              ))}
            </div>
          </div>

          <img src={glow1} alt="glow" className="absolute top-0 left-0 right-0 m-auto object-cover max-lg:h-[400px]" />

        </div>
      </div>
    </section>
  );
}

export default Tokenomics;
