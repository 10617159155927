import { useState } from 'react';
import logo from '../assets/img/logo-arcane.png';
import twitter from '../assets/img/twitter.svg';
import telegram from '../assets/img/telegram.svg';

function Copyright() {
  const [year, setYear] = useState(2023)
  const yearNow = new Date().getFullYear()
  if ( yearNow > year ) setYear(yearNow)

  return (
    <section id="copyright">
      <div className="bg-primary-1">
        <div className="section bg-primary-1">
          <div className="section bg-primary-2 w-3/4 h-3"></div>
        </div>
      </div>
      <div className="relative bg-background-1 text-white">
        <div className="section flex items-center justify-between gap-4 py-8 max-lg:flex-col">

          <div className="flex justify-center gap-6">
            <img src={logo} className="brand invert-white max-lg:w-3/4" alt="logo" />
          </div>

          <div className="flex justify-center gap-6 max-lg:mb-4">
            <a href="https://twitter.com/arcanelabyrinth" target="_blank" rel="noopener noreferrer" className="transition-all hover:drop-shadow-[0_2px_8px_rgba(227,193,119,.5)]">
              <img src={twitter} alt="twitter" className="w-5" />
            </a>
            <a href="https://t.me/arcanexrd" target="_blank" rel="noopener noreferrer" className="cursor-pointer transition-all hover:drop-shadow-[0_2px_8px_rgba(227,193,119,.5)]">
              <img src={telegram} alt="telegram" className="w-5" />
            </a>
          </div>

          <div className="text-xs text-center lg:text-right">
            Copyright &copy; {year} Arcane Labyrinth<span className="lg:hidden">. </span><br className="max-lg:hidden" />
            Company Legal Holdings Reserved
          </div>

        </div>
        <div className="bg-gradient-footer absolute right-20 lg:right-40 bottom-0 h-16 lg:h-1/2 w-1/2 lg:w-[300px]"></div>
        <div className="bg-gradient-footer absolute right-0 bottom-0 h-16 lg:h-1/2 w-1/2 lg:w-[300px]"></div>
      </div>
    </section>
  );
}

export default Copyright;
