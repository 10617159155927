import React, { useEffect } from 'react';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import glow1 from '../assets/img/glow-1.png';
import gradient from '../assets/img/pattern-story.svg';
import walk from '../assets/img/character-1-walk.png';
import walk1 from '../assets/img/character-1-walk-1.png';
import walk2 from '../assets/img/character-1-walk-2.png';
import story from '../assets/img/story.svg';
import story1 from '../assets/img/story-1.svg';

function Card(props) {
  useEffect(() => {
    document.querySelectorAll('#story .splide__list').forEach(item => {
      let prev = item.querySelector('.splide__slide:first-child .navigate-prev');
      let next = item.querySelector('.splide__slide:last-child .navigate-next');
      if ( prev !== null ) {
        prev.setAttribute('disabled','disabled');
      }
      if ( next !== null ) {
        next.setAttribute('disabled','disabled');
      }
    });
  });

  const handleClickPrev = () => {
    document.querySelectorAll('#story .splide__arrow--prev').forEach(item => {
      item.click();
    });
  };

  const handleClickNext = () => {
    document.querySelectorAll('#story .splide__arrow--next').forEach(item => {
      item.click();
    });
  };

  return (
    <div className="card-story">
      <img src={props.thumbnail} alt="bg" className="w-full lg:w-1/2 rounded-lg invisible" />

      <div className="relative max-lg:flex max-lg:flex-col w-full lg:w-1/2">
        <div className="flex items-start justify-between gap-4">

          <h3 className={"title gradient " + (props.title ? '' : 'hidden' )}>
            {props.title}
          </h3>

          <div className="navigate">
            <div className="navigate-prev" onClick={handleClickPrev}>
              <ArrowLongRightIcon className="w-5 h-5" />
            </div>
            <div className="navigate-next" onClick={handleClickNext}>
              <ArrowLongRightIcon className="w-5 h-5" />
            </div>
          </div>

        </div>

        <hr className={"bg-primary-1 pt-px my-6 " + (props.title ? '' : 'hidden' )} />

        <p className="lg:mt-8">
          {props.content.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>

        <div className="max-lg:w-3/5 max-lg:mx-auto max-lg:mt-auto">
          <div className="font-theme-1 text-right mb-3">{props.step}</div>
          <div className="progress">
            <div className="bar" style={{width: props.width}}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Story() {
  const data = [
    {
      title: "Once Upon a Time...",
      content: `In a remote village, there lived an adventurer named Jack. Jack is a reliable adventurer who often travels the world in search of new adventures. One day, Jack finds a mysterious portal in the middle of the forest. Without thinking, Jack jumps into the portal, and is suddenly trapped in another world full of monsters and mazes.`,
      thumbnail: `${story}`,
      step: "1 / 5",
      width: "20%"
    },
    {
      title: "",
      content: `Jack wakes up on a floor with walls made of stone. He didn't know how to get out of that place, suddenly a magician came and said to Jack that he had to conquer the 101 floor maze to get out and return to his original world.\n\nThe Witch said that the fate of the world was in Jack's hands and he had to defeat the Witch by completing the 101 floors of the labyrinth to save her world, but suddenly the evil witch disappeared.`,
      thumbnail: `${story}`,
      step: "2 / 5",
      width: "40%"
    },
    {
      title: "",
      content: `Then Jack started walking towards the open door, but suddenly vicious monsters appeared who wanted to kill him.\n\nJack swiftly drew his sword, and defeated the monsters with his fighting skills as an adventurer. Jack realized that he couldn't clear this floor with just his physical strength and his magic. He must find the keys hidden in the maze to open the door on the next floor.`,
      thumbnail: `${story}`,
      step: "3 / 5",
      width: "60%"
    },
    {
      title: "",
      content: `Jack went around the room and searched for the keys. Every time Jack finds a key, he must defeat monsters stronger than before. The deeper Jack goes into the labyrinth, the bigger the challenge he has to face.`,
      thumbnail: `${story}`,
      step: "4 / 5",
      width: "80%"
    },
    {
      title: "",
      content: `Will Jack survive?! Or will they be trapped forever in the labyrinth with ferocious and dangerous monsters?!\n\nConquer 101 floors of the maze and find out what's next!`,
      thumbnail: `${story}`,
      step: "5 / 5",
      width: "100%"
    }
  ]

  return (
    <section id="story">
      <div className="section pb-14">
        <div className="relative bg-background-1 text-white rounded-xl overflow-hidden md:max-w-screen-sm md:mx-auto lg:max-w-[initial]">

          <h2 className="sr-only">Story</h2>

          <div className="relative z-[1] p-10">
            <div className="w-[calc(100%-5rem)] lg:w-[calc(50%-2.5rem)] rounded-lg overflow-hidden absolute top-10 left-10 z-[1]">
              <img src={walk1} alt="walk" className="walking-1 w-1/4 absolute bottom-4 left-0 right-0 mx-auto" />
              <img src={walk2} alt="walk" className="walking-2 w-1/4 absolute bottom-4 left-0 right-0 mx-auto" />
              <img src={walk} alt="walk" className="w-1/4 absolute bottom-4 left-0 right-0 mx-auto" />
              <img src={story1} alt="bg" className="w-full" />
            </div>
            <Splide hasTrack={ false } aria-label="Benefits" className="-mx-10" options={ {
              perMove: 1,
              perPage: 1,
              pagination: false,
              type: 'fade',
            } }>
              <SplideTrack>
                {data.map((item, index) => (
                  <SplideSlide key={index}>
                    <Card
                      title={item.title}
                      content={item.content}
                      thumbnail={item.thumbnail}
                      step={item.step}
                      width={item.width}
                    />
                  </SplideSlide>
                ))}
              </SplideTrack>
              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev">
                  <ArrowLongRightIcon className="w-4 h-4" />
                </button>
                <button className="splide__arrow splide__arrow--next">
                  <ArrowLongRightIcon className="w-4 h-4" />
                </button>
              </div>
            </Splide>
          </div>

          <img src={gradient} alt="glow" className="absolute lg:top-0 bottom-0 left-0 m-auto object-cover lg:w-1/2 lg:h-full max-lg:w-full max-lg:h-[200px] max-lg:object-bottom" />

          <img src={glow1} alt="glow" className="absolute top-0 right-0 m-auto object-cover lg:w-1/2 max-lg:h-[300px] sepia-[50%]" />

        </div>
      </div>
    </section>
  );
}

export default Story;
