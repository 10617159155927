import corner1 from '../assets/img/corner-1.svg';

function Card(props) {
  return (
    <div className="relative sm:w-[calc(50%-1.5rem)] lg:w-[calc(33.3333%-1.5rem)] text-left p-6 overflow-hidden rounded-lg shadow-[0_0_25px_-3px_rgba(0,0,0,0.1)]">
      <img src={corner1} alt="corner" className="w-12 absolute top-0 right-0" />
      <h3 className="font-theme-1 font-bold text-gray-800 text-lg lg:text-xl pr-8 mb-4">
        {props.title}
      </h3>
      <p>{props.content}</p>
    </div>
  );
}

function Mission() {
  const data = [
    {
      title: 'Create an eye-catching and stunning game design',
      content: 'Create an eye-catching and stunning game design by incorporating beautiful and imaginative 2D pixel art graphics that match the theme of the magical fantasy world.'
    },
    {
      title: 'Makes gameplay challenging, fair and fun',
      content: 'Makes gameplay challenging, fair and fun by presenting a wide variety of interesting enemies, puzzles and obstacles, as well as providing a play-to-earn system that gives players the chance to earn real prizes.'
    },
    {
      title: 'Provides a co-op multiplayer feature',
      content: 'Provides a co-op multiplayer feature that allows players to play together and help each other in completing the floors in the maze.'
    },
    {
      title: 'Create an interesting and innovative story',
      content: 'Create an interesting and innovative story that can make players feel involved in the game and provide a more lively and enjoyable atmosphere.'
    },
    {
      title: 'Constantly developing and improving the game',
      content: 'Constantly developing and improving the game with new features and updates to keep players interested and wanting to play for a long time.'
    }
  ]
  return (
    <section id="mission">
      <div className="relative text-gray-500">
        <div className="section flex items-center justify-center lg:justify-between gap-4 max-lg:flex-wrap my-14 lg:my-20 text-center">

          <div className="max-w-screen-xl mx-auto">
            <div data-title="Our" className="title gradient my-7 lg:my-9 before:content-[attr(data-title)] before:font-normal before:text-xl before:absolute before:-top-7 before:left-0 before:right-0 before:m-auto before:text-gray-800 lg:before:text-3xl lg:before:-top-10">
              Mission
            </div>
            <h2 className="sr-only">Our Mission</h2>
            <div className="flex justify-center flex-wrap gap-8">
              {data.map((item,index) => (
                <Card 
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Mission;
