import char1 from '../assets/img/character-1.png';
import char2 from '../assets/img/character-2.png';

function Welcome() {
  return (
    <section id="welcome">
      <div className="relative text-gray-500">
        <div className="section flex items-center justify-center lg:justify-between gap-4 max-lg:flex-wrap pb-10 text-center">

          <div className="lg:order-2 lg:w-3/5 mx-auto my-10 px-4 z-[1]">
            <h2 className="font-theme-1 font-bold text-2xl sm:text-4xl lg:text-5xl text-gray-800 my-9">
              “Explore, Conquer, Earn”
            </h2>
            <p>This is an adventure game project that offers the experience of playing in a labyrinth that has 101 different floors. With a fantasy world theme and an attractive pixel art style, players will feel nostalgic when playing this game. More than that, this game offers a play-to-earn system that allows players to get prizes in the form of $ARC tokens when they successfully complete each floor of the maze.</p>
          </div>

          <div className="lg:order-1 max-lg:w-2/5 relative inline mx-auto lg:mt-10 z-[1]">
            <div className="bg-gradient-to-t from-white to-transparent absolute bottom-0 w-full h-1/5 z-[2]"></div>
            <div className="bg-gradient-to-t from-white to-transparent absolute bottom-0 w-full h-3/5 z-[1]"></div>
            <img src={char1} alt="character" className="max-sm:h-[250px] lg:w-[200px] lg:h-[400px] object-cover" />
          </div>

          <div className="lg:order-3 max-lg:w-2/5 relative inline mx-auto lg:mt-10 z-[1]">
            <div className="bg-gradient-to-t from-white to-transparent absolute bottom-0 w-full h-1/5 z-[2]"></div>
            <div className="bg-gradient-to-t from-white to-transparent absolute bottom-0 w-full h-3/5 z-[1]"></div>
            <img src={char2} alt="character" className="max-sm:h-[250px] lg:w-[200px] lg:h-[400px] object-cover transform -scale-x-100" />
          </div>

        </div>
        <div className="bg-gradient-welcome absolute top-0 left-0 w-1/2 h-[100px]"></div>
        <div className="bg-gradient-welcome absolute lg:top-52 right-0 w-1/2 h-[100px] rotate-180 max-lg:bottom-40"></div>
      </div>
    </section>
  );
}

export default Welcome;
