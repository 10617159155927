import React from 'react';

import controller from '../assets/img/controller.svg';
import play from '../assets/img/play-circle.svg';
// import bg from '../assets/img/preview.jpeg';
// import button from '../assets/img/preview-button.png';
// import buttonHover from '../assets/img/preview-button-hover.png';

function Preview() {
  return (
    <section id="preview">
      <div className="section relative z-[1] pb-14 lg:pb-20 overflow-x-hidden">
        <div className="text-center">

          <h2 className="title gradient mt-6 mb-6 lg:mt-8 lg:mb-10">
            Game Preview & Demo Game
          </h2>

          {/* <div className="md:max-w-screen-md mx-auto">
            <div className="relative block rounded-xl overflow-hidden bg-black">
              <a href="/preview/" className="block absolute top-0 bottom-0 right-0 left-0 m-auto z-[1] max-w-[60%] h-fit group">
                <img src={button} alt="button" className="mx-auto group-hover:hidden" />
                <img src={buttonHover} alt="button" className="mx-auto group-hover:block hidden" />
              </a>
              <img src={bg} alt="bg" className="opacity-30" />
            </div>
          </div> */}

          <div className="w-fit mx-auto flex flex-wrap gap-6 mt-6">
            <a href="/preview/" className="button primary lg:text-lg w-full lg:w-fit mx-auto flex items-center justify-center">
              <img src={play} alt="icon" className="w-6 mr-2 invert" /> Watch Demo Game Preview
            </a>
            <a href="/game/" className="button primary lg:text-lg w-full lg:w-fit mx-auto flex items-center justify-center">
              <img src={controller} alt="icon" className="w-6 mr-2 invert" /> Play Demo Game
            </a>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Preview;
